.grid--brand {
	grid-template-columns: 20% 20% 20% 20% 20%;
	grid-template-rows: auto auto auto auto auto;
	column-gap: 0px;
	row-gap: 0;
	display: grid;
    max-width: 1300px;
    width: 100%;
    margin: auto;
}

@media only screen and (max-width: 650px) {
	.grid--brand {
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto;
		column-gap: 0px;
		row-gap: 0;
		display: grid;
	}
}

.item--brand {
    width: 90%;
    margin: 25px auto;
    height: calc(100% - 50px);
}

.item--brand table {
    width: 100%;
    table-layout: fixed;
    height: 100%;
}

.item--brand table td {
    padding: 15px;
    text-align: center;
}

.item--brand table thead td {
    padding-bottom: 0;
}

.item--brand h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 7px;
}