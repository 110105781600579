footer {
  text-align: center;
  padding: 40px 30px;
  background-color: #000;
  border-top: 1px solid #444;
}

footer p {
  font-size: 15px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  line-height: 26px;
}

footer p.small {
  text-align: justify;
  font-size: 12px;
  line-height: 20px;
  font-weight: 200;
  color: #f9f9f9;
}

footer a {
  text-decoration: none;
  color: #fff;
}

footer a:hover {
  text-decoration: underline;
  color: #fff;
}

.footer-grid {
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: auto auto auto auto auto;
  grid-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0;
  grid-row-gap: 0;
  row-gap: 0;
  display: grid;
}

@media only screen and (max-width: 900px) {
  .footer-grid {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
    grid-row-gap: 0;
    row-gap: 0;
    display: grid;
  }
  .footer-block {
    margin-bottom: 30px;
  }
}

.footer-block {
  text-align: left;
  padding-right: 20%;
}

footer h6 {
  font-size: 12px;
  color: #666;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 15px;
}

footer hr {
  border-color: #444;
}

footer .footer-block ul {
  margin: 0;
  padding: 0;
}

footer .footer-block li {
  list-style-type: none;
  font-size: 15px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  line-height: 26px;
}

footer .footer-logo {
  margin-top: 15px;
}