.column--filters {
    padding: 0 20px;
    position: relative;
    padding-bottom: 70px;
}

.apply-btn-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 2;
    border-top: 1px solid #ddd;
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
    overflow: hidden;
}
.apply-btn-fixed .btn {
    margin-bottom: 20px;
    margin-top: 10px;
}

.grid--product {
	grid-template-columns: 33.33% 33.33% 33.33%;
	grid-template-rows: auto auto auto;
	column-gap: 0px;
	row-gap: 0;
	display: grid;
    max-width: 1300px;
    width: 100%;
    margin: auto;
}

@media only screen and (max-width: 650px) {
	.grid--product {
		grid-template-columns: 100%;
		grid-template-rows: auto;
		column-gap: 0px;
		row-gap: 0;
		display: grid;
	}
}

@media only screen and (max-width: 1050px) and (min-width: 650px) {
	.grid--product {
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto;
		column-gap: 0px;
		row-gap: 0;
		display: grid;
	}
}

.item--product {
    background-color: #eee;
    width: 90%;
    margin: 25px auto;
    height: calc(100% - 50px);
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 8%);
    border: 1px solid #e9e9e9;
}

.item--product table {
    width: 100%;
    table-layout: fixed;
    height: 100%;
}

.item--product table td {
    padding: 15px;
    text-align: center;
    position: relative;
}

.item--product table thead td {
    padding-bottom: 0;
}

.item--product h3 {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0px;
}

.item--product table tr.tr-icons td {
    background-color: #fff;
    padding: 7px;
}

.item--product .product--icon {
    cursor: pointer;
}

.item--product .price {
    margin: 0;
    margin-bottom: 0;
    font-weight: 600;
    color: #32899f;
    font-size: 13px;
    letter-spacing: 1px;
}

.item--product .available {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: rgba(256,256,256,0.8);
    padding: 4px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #666;
}

.price s {
    font-weight: 300;
    color: #000;
}

.product-table .available {
    background-color: #eee;
    padding: 4px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #666;
    float: right;
}

.product-table img {
    max-width: 100%;
}

.product-table .price {
    font-weight: 600;
    color: #32899f;
    letter-spacing: 1px;
    font-size: 15px;
}

.product-table .product-gallery {
    background-color: transparent;
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 30px;
    margin-bottom: 30px;
}

.product-table .product-gallery li {
    display: inline-block;
}

.product-table .product-gallery li img {
    height: 60px;
    margin: 5px;
}

.product-table .content {
    margin-bottom: 40px;
}

.whatsapp-product {
	background-color: #eee;
	text-align: center;
	min-height: 280px;
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
} 

.whatsapp-product h2 {
	color: #333;
    margin: 0;
}

#left-tabs-product {
    background-color: transparent;
}

#left-tabs-product .nav-pills .nav-link {
    border-radius: 0;
    color: #333;
    background-color: #eee;
    padding: 15px 20px;
    margin-bottom: 5px;
}

#left-tabs-product .nav-pills .nav-link.active {
    background-color: #32899f;
    color: #fff;
}

#tab-product-content {
    background-color: #eee;
    padding: 0 20px;
}

#tab-product-content h3 {
    font-size: 20px;
    text-transform: uppercase;
}

table.product-details {
    width: 100%;
    table-layout: fixed;
    max-width: 400px;
}

table.product-details td {
    padding: 5px;
}

table.product-details th {
    padding: 5px;
    font-weight: 600;
}

#wa-fixed {
    position: fixed;
    bottom: 60px;
    right: 10px;
}

p.datasheet a {
    cursor: pointer;
}

.variants-container {
    overflow: hidden;
}

.variant-item {
    padding: 5px;
    display: inline-block;
    border: 1px solid #fff;
    margin: 3px;
}

.variant-item.selected {
    border-color: rgb(159, 69, 69);
}

.variant-item img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

p.available {
    font-size: 14px;
    line-height: 30px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.product-grid-image-container {
    position: relative;
}

.product-grid-image-container::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.product-grid-image {
    object-fit: contain;
	width: 100%;
	height: 100%;
	position: absolute;
    left: 0;
    top: 0;
}