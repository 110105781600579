body {
	padding-top: 70px;
}

.main {
  min-height: 400px;
  padding: 0;
}

.slide-image {
	background-color: #eee;
	text-align: center;
	min-height: 280px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-size: cover;
}

.slide-image h2 {
	color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: 1px 1px #000;
}