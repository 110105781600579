header {
    background-color: #000;
    z-index: 1002;
    backdrop-filter: blur(10px);
    transition: 1.3s;
    transition-property: all;
    transition-timing-function: ease;
    transition-property: height;
    transition-timing-function: cubic-bezier(.23, 1, .32, 1);
    will-change: height;
    overflow: hidden;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

header.menu--active {
    height: 100vh;
}

header .menu {
    opacity: 0.1;
    transition: 1.3s;
    transition-property: all;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(.23, 1, .32, 1);
    will-change: all;
    padding: 100px 20px 60px;
}

header.menu--active .menu {
    opacity: 1;
    margin-top: 3%;
}

#burger--menu {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 30px;
    cursor: pointer;
    font-size: 11px;
    z-index: 2;
    text-align: center;
    color: #fff;
}

#burger--menu::before {
    display: block;
    content: "";
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    height: 10px;
    margin-top: 8px;
    transition: all 0.2s ease-in;
}

header.menu--active #burger--menu::before {
    height: 0;
    margin-top: 16px;
    border-width: 1px;
}

#search-btn {
    position: absolute;
    top: 20px;
    right: 100px;
    width: 40px;
    height: 30px;
    cursor: pointer;
    z-index: 2;
}

#user-btn {
    position: absolute;
    top: 20px;
    right: 60px;
    width: 40px;
    height: 30px;
    cursor: pointer;
    z-index: 2;
}

#cart-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 30px;
    cursor: pointer;
    z-index: 2;
}

header .menu ul {
    margin: 0;
    padding: 0;
    text-align: center;
}

header .menu ul li {
    display: block;
    margin: 0;
    padding: 16px;
    line-height: 36px;
    font-weight: 500;
    font-size: 30px;
}

header .menu ul li a {
    color: #fff;
    text-decoration: none;
}

header .menu ul li.active a {
    color: rgb(248, 132, 119);
}

header .logo {
    width: 120px;
    height: 56px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    display: inline-block;
    background-color: transparent;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    text-align: center;
}

.subheader {
    position: sticky;
    width: 100%;
    top: 70px;
    line-height: 24px;
    background-color: #000;
    z-index: 101;
    text-align: center;
    padding: 7px;
}

.subheader li {
    list-style-type: none;
    display: inline-block;
    color: #fff;
    padding: 5px 12px;
}

.subheader li a {
    text-decoration: none;
    color: #fff;
}

@media only screen and (max-width: 650px) {
    .subheader {
        display: none;
    }
    .sub-subheader {
        display: none;
    }
}

.number-cart {
    border-radius: 20px;
    background-color: #eee;
    width: 20px;
    height: 20px;
    display: block;
    text-align: center;
    line-height: 20px;
    font-size: 11px;
    font-weight: 600;
    position: absolute;
    top: -5px;
    right: -5px;
}

.sub-subheader {
    background-color: #222;
    padding: 20px 10%;
    column-count: 4;
    transition: all 0.2s ease-in;
}

.sub-subheader a {
    color: #fff;
    text-decoration: none;
}

.sub-subheader img {
    transition: all 0.2s ease-in;
}

.sub-subheader li {
    list-style-type: none;
}