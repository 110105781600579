.products-page .container-fluid {
    padding: 0 !important;
    margin: auto !important;
}

.slide-category-container {
    background-size: cover;
    background-position: center;
    background-color: #eee;
}

.slide--category {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    width: 100%;
    text-align: left;
    min-height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding: 10px 40px;
}

.slide--category h1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 800;
    color: #fff;
}

.grid--category {
	grid-template-columns: 50% 50%;
	grid-template-rows: auto auto;
	column-gap: 0px;
	row-gap: 0;
	display: grid;
    max-width: 1300px;
    width: 100%;
    margin: auto;
}

@media only screen and (max-width: 900px) {
	.grid--category {
		grid-template-columns: 100%;
		grid-template-rows: auto;
		column-gap: 0px;
		row-gap: 0;
		display: grid;
	}
}

.item--category {
    background-color: #ddd;
    width: 90%;
    margin: 25px auto;
    height: calc(100% - 50px);
}

.item--category table {
    width: 100%;
    table-layout: fixed;
    height: 100%;
}

.item--category table td {
    padding: 15px;
}

.item--category table thead td {
    padding-bottom: 0;
}

.item--category h3 {
    font-size: 20px;
    text-transform: uppercase;
}

#chevron-filters {
    background-color: #eee;
    border-radius: 0 50px 50px 0;
    height: 60px;
    left: 0;
    line-height: 60px;
    opacity: .9;
    position: fixed;
    text-align: center;
    top: 40%;
    transition: all .2s ease-in;
    width: 60px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #999;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
}

#chevron-filters:hover {
    width: 70px;
}

.filters-chevron-text {
    margin: 0;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 10px;
    color: #666;
}

.variantsCount {
    background-color: #fef3f3;
    color: #666;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    display: block;
    font-size: 14px;
}