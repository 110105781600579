@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif !important;
}

main a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

pre,
code {
	overflow: auto;
	max-width: 100%;
}

main h1 {
	font-size: 60px;
	line-height: 80px;
	margin: 20px 0;
}

main h2 {
	font-size: 36px;
	margin: 20px 0;
	line-height: 60px;
}

main h3,
main h4 {
	font-size: 30px;
	margin: 20px 0;
}

main h5 {
	font-size: 28px;
	margin: 18px 0;
}

main p,
main blockquote,
main li {
	font-size: 18px !important;
	font-weight: 300;
	line-height: 30px;
	-webkit-backface-visibility: hidden;
}

main p.small {
	font-size: 14px;
	line-height: 22px;
}